export const logger = {
    error: (...args) => {
      if (process.env.NODE_ENV === 'development') {
        console.error(...args);
      }
    },
    log: (...args) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(...args);
      }
    }
  };