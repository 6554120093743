import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar.js';
import './Products.css';
import { logger } from '../../utils/logger';

function Products() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadedImages, setLoadedImages] = useState(new Set());
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('전체');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1014);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products`);
        if (!response.ok) {
          throw new Error('서버 응답 에러');
        }
        const productsData = await response.json();
        setProducts(productsData);
        setLoading(false);
      } catch (error) {
        logger.error('상품 조회 중 오류:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    if (!loading && products.length > 0) {
      const totalImages = products.length;
      let loadedCount = 0;

      const preloadImage = (url) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            loadedCount++;
            setLoadedImages(prev => new Set([...prev, url]));
            if (loadedCount === totalImages) {
              setImagesLoaded(true);
            }
            resolve();
          };
          img.onerror = () => {
            loadedCount++;
            if (loadedCount === totalImages) {
              setImagesLoaded(true);
            }
            resolve();
          };
        });
      };

      Promise.all(products.map(product => preloadImage(product.imageUrl)));
    }
  }, [loading, products]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1014);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const categories = ['전체', '매크로', '티켓팅', '골프'];

  const filteredProducts = selectedCategory === '전체'
    ? products
    : products.filter(product => product.category === selectedCategory);

  const handleProductClick = (productId) => {
    navigate(`/Details?id=${productId}`);
  };

  return (
    <>
      <motion.div
        className="navbar-container"
        animate={{ y: visible ? 0 : -200 }}
        transition={{ duration: 0.3 }}
      >
        <Navbar />
      </motion.div>

      <div className={`products-page ${!isMobile ? 'desktop-layout' : ''}`}>
        <div className="products-header">
          <h1>상품</h1>
          <p>개발자 경험을 나누는, 76DEV의 상품입니다.</p>
        </div>

        <div className="category-filter">
          {categories.map((category) => (
            <button
              key={category}
              className={`category-button ${selectedCategory === category ? 'active' : ''}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {(loading || !imagesLoaded) ? (
          <div className="loading-container">
            <div className="loading-content">
              <div className="spinner"></div>
              <p className="loading-text">상품을 불러오는 중입니다...</p>
            </div>
          </div>
        ) : (
          <AnimatePresence>
            <motion.div 
              className="products-grid"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {filteredProducts.map((product) => (
                <motion.div
                  key={product.id}
                  className="product-card"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  whileHover={{ y: -10 }}
                  onClick={() => handleProductClick(product.id)}
                >
                  <div className="product-image-container">
                    {loadedImages.has(product.imageUrl) && (
                      <img 
                        src={product.imageUrl} 
                        alt={product.name}
                        className="product-image"
                      />
                    )}
                    <div className="product-badges">
                      {product.isPopular && (
                        <span className="popular-badge">인기</span>
                      )}
                      {process.env.NODE_ENV === 'development' && product.isPrivate && (
                        <span className="private-badge" title="비공개 상품">
                          👁️
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="product-info">
                    <h3>{product.name}</h3>
                    <p className="product-description">{product.description}</p>
                    <div className="price-container">
                      <div className="price-info">
                        <span className="discount-rate">
                          {Math.round((1 - product.currentPrice / product.originalPrice) * 100)}%
                        </span>
                        <span className="current-price">
                          {product.currentPrice.toLocaleString()}원
                        </span>
                      </div>
                      <span className="original-price">
                        {product.originalPrice.toLocaleString()}원
                      </span>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </>
  );
}

export default Products;