import React, { useState, useEffect } from 'react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../../firebase';
import './LoginModal.css';
import api from '../../utils/api';

function LoginModal({ isOpen, onClose, initialMode = 'login' }) {
  const [isLogin, setIsLogin] = useState(initialMode === 'login');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setIsLogin(initialMode === 'login');
  }, [initialMode]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
      setError('');
      setIsProcessing(false);
    }
  }, [isOpen]);

  const handleGoogleAuth = async () => {
    if (isProcessing) return;
    
    try {
      setIsProcessing(true);
      setError('');
      
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      
      const response = await api.auth.verifyToken(idToken);
      const data = await response.json();
      
      if (data.user) {
        onClose();
      } else {
        throw new Error('인증 데이터를 받아오지 못했습니다.');
      }
    } catch (error) {
      console.error('Google 로그인 실패:', error);
      setError(error.message || '로그인 처리 중 오류가 발생했습니다.');
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button 
          className="close-button" 
          onClick={onClose}
          disabled={isProcessing}
        >×</button>
        
        <div className="modal-header">
          <h2>{isLogin ? '로그인' : '회원가입'}</h2>
          <p className="modal-subtitle">
            {isLogin 
              ? '76DEV에 오신 것을 환영합니다' 
              : '76DEV와 함께 시작하세요'}
          </p>
        </div>

        {error && (
          <div className="error-message">
            {error}
            <button 
              className="retry-button" 
              onClick={() => {
                setError('');
                setIsProcessing(false);
              }}
            >
              다시 시도
            </button>
          </div>
        )}

        <div className="auth-container">
          <button 
            className="google-auth-button" 
            onClick={handleGoogleAuth}
            disabled={isProcessing}
          >
            <div className="google-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
              </svg>
            </div>
            <span>
              {isProcessing ? '처리 중...' : `Google로 ${isLogin ? '로그인' : '회원가입'}`}
            </span>
          </button>
        </div>

        <div className="mode-switch">
          <p className="mode-switch-text">
            {isLogin ? '아직 회원이 아니신가요?' : '이미 계정이 있으신가요?'}
            <button className="mode-switch-button" onClick={() => setIsLogin(!isLogin)}>
              {isLogin ? '회원가입' : '로그인'}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginModal;