import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ReactGA from 'react-ga4';

// Google Analytics 초기화
// 여기에 본인의 Measurement ID를 넣으세요 (G-XXXXXXXXXX 형식)
ReactGA.initialize('G-CC353MPL5Z');

// 페이지뷰 추적 설정
ReactGA.send({ hitType: "pageview", page: window.location.pathname });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);