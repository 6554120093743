import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Navbar from '../../components/Navbar/Navbar';
import './Details.css';
import { logger } from '../../utils/logger';
import { useInView } from 'react-intersection-observer';
import { loadTossPayments } from '@tosspayments/payment-sdk';

function Details() {
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('id');
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showPolicyPopup, setShowPolicyPopup] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // InView 훅 추가
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/${productId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProduct(data);
        setLoading(false);
      } catch (error) {
        logger.error('상품 조회 중 오류:', error);
        setLoading(false);
        setProduct(null);
      }
    };

    if (productId) {
      fetchProduct();
    }
  }, [productId]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const handlePayment = async () => {
    try {
      const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
      
      // 주문 ID 생성 (현재 시간 + 랜덤 문자열)
      const orderId = new Date().getTime() + '_' + Math.random().toString(36).substr(2, 9);
      
      await tossPayments.requestPayment('카드', {
        amount: product.currentPrice,
        orderId: orderId,
        orderName: product.name,
        customerName: '구매자',
        successUrl: `${window.location.origin}/payment/success`,
        failUrl: `${window.location.origin}/Details?id=${productId}`,
      });
    } catch (error) {
      if (error.code === 'USER_CANCEL') {
        return;
      } else if (error.code === 'INVALID_CARD_COMPANY') {
        alert('유효하지 않은 카드입니다.');
      } else {
        alert('결제 중 오류가 발생했습니다.');
        console.error('결제 에러:', error);
      }
    }
  };

  // 팝업 닫기 핸들러 수정
  const handleClosePopup = (e) => {
    if (e.target.classList.contains('policy-popup-overlay')) {
      setIsClosing(true);  // 닫기 애니메이션 시작
      setTimeout(() => {
        setShowPolicyPopup(false);  // 애니메이션 완료 후 팝업 제거
        setIsClosing(false);
      }, 300);  // CSS 애니메이션 시간과 동일하게 설정
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!product) {
    return <div className="error-message">상품을 찾을 수 없습니다.</div>;
  }

  return (
    <>
      <motion.div
        className="navbar-container"
        animate={{ y: visible ? 0 : -200 }}
        transition={{ duration: 0.3 }}
      >
        <Navbar />
      </motion.div>

      {/* 상품 정보 섹션 */}
      <div className="page-wrapper">
        <div className="details-page">
          <div className="details-container">
            <div className="product-gallery">
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                src={product.imageUrl}
                alt={product.name}
                className="main-image"
              />
            </div>

            <div className="product-info">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <h1>{product.name}</h1>
                <div className="price-section">
                  <div className="discount-info">
                    <span className="discount-rate">
                      {Math.round((1 - product.currentPrice / product.originalPrice) * 100)}%
                    </span>
                    <span className="current-price">
                      {product.currentPrice.toLocaleString()}원
                    </span>
                  </div>
                  <span className="original-price">
                    {product.originalPrice.toLocaleString()}원
                  </span>
                </div>

                <div className="pc-purchase-section">
                  <button className="purchase-button" onClick={handlePayment}>
                    구매하기
                  </button>
                </div>

                <div className="product-description">
                  <h2>상품 설명</h2>
                  <p>{product.description}</p>
                </div>

                <div className="download-info">
                  <h2>구매 후 이용 방법</h2>
                  <ul>
                    <li>결제 완료 즉시 다운로드 링크가 제공됩니다.</li>
                    <li>프로그램 설치 가이드 PDF가 함께 제공됩니다.</li>
                    <li>기술 지원은 이메일로 24시간 가능합니다.</li>
                  </ul>
                  <div className="policy-info">
                    <button 
                      className="policy-button"
                      onClick={() => setShowPolicyPopup(true)}
                    >
                      제공기간 및 환불 정책
                    </button>
                  </div>
                </div>

                {showPolicyPopup && (
                  <div 
                    className={`policy-popup-overlay ${isClosing ? 'closing' : ''}`} 
                    onClick={handleClosePopup}
                  >
                    <div className="policy-popup">
                      <div className="policy-content">
                        <h3>[제공 기간]</h3>
                        <ul>
                          <li>상품 구매 시점으로부터 평생 이용 가능</li>
                          <li>단, 서비스 종료 시 최소 3개월 전 고지 후 종료될 수 있음</li>
                        </ul>
                        <h3>[환불 정책]</h3>
                        <ul>
                          <li>구매 직후 다운로드/열람 시 환불 불가</li>
                          <li>기술적 문제로 인한 이용 불가 시 100% 환불</li>
                          <li>미다운로드/미열람 상태에서 24시간 이내 청약철회 가능</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mobile-purchase-section">
                  <button className="purchase-button" onClick={handlePayment}>
                    구매하기
                  </button>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* 완전히 독립된 상세 이미지 섹션 */}
      <div className="detail-images-container">
        <div className="detail-images-wrapper">
          <motion.div 
            ref={ref}
            className="detail-images-section"
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6 }}
          >
            {product.detailImages?.map((imageUrl, index) => (
              <motion.img
                key={index}
                src={imageUrl}
                alt={`상세 이미지 ${index + 1}`}
                className="detail-image"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              />
            ))}
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default Details;
