import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Landing from './pages/Landing/Landing';
import MyPage from './pages/Mypage/Mypage';
import Products from './pages/Products/Products';
import Details from './pages/Details/Details';
import PaymentComplete from './pages/Payment/PaymentComplete';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import Footer from './components/Footer/Footer';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
`;

// 페이지 변경을 추적하는 컴포넌트
function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppWrapper>
          <PageTracker />
          <MainContent>
            <Routes>
              {/* 공개 라우트 */}
              <Route path="/" element={<Landing />} />
              <Route path="/products" element={<Products />} />
              <Route path="/details" element={<Details />} />
              <Route path="/payment/success" element={<PaymentComplete />} />
              <Route path="/payment/fail" element={<PaymentComplete />} />
              
              {/* 보호된 라우트 */}
              <Route path="/mypage" element={
                <PrivateRoute>
                  <MyPage />
                </PrivateRoute>
              } />
            </Routes>
          </MainContent>
          <Footer />
        </AppWrapper>
      </Router>
    </AuthProvider>
  );
}

export default App;