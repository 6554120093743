import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import Navbar from '../../components/Navbar/Navbar';
import './Mypage.css';

function MyPage() {
  const { user, loading } = useAuth();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    if (!loading && !user) {
      navigate('/', { 
        state: { 
          showLoginModal: true,
          returnPath: '/mypage',
          fromDirectAccess: true
        } 
      });
    }
  }, [user, loading, navigate]);

  // Navbar 스크롤 애니메이션
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p className="loading-text">프로필 정보를 불러오는 중...</p>
      </div>
    );
  }

  if (!user) return null;

  return (
    <>
      <motion.div
        className="navbar-container"
        animate={{ y: visible ? 0 : -200 }}
        transition={{ duration: 0.3 }}
      >
        <Navbar />
      </motion.div>

      <div className="page-wrapper">
        <div className="mypage-container">
          <div className="mypage-grid">
            {/* 왼쪽 사이드바 */}
            <div className="sidebar">
              <div className="profile-card">
                <div className="profile-header">
                  <img 
                    src={user.picture || "https://i.imgur.com/gh3wZti.png"}
                    alt="Profile" 
                    className="profile-image"
                  />
                  <div className="profile-info">
                    <h2 className="profile-name">{user.name || user.email.split('@')[0]}</h2>
                    <p className="profile-email">{user.email}</p>
                  </div>
                </div>
              </div>
              
              <nav className="menu-nav">
                <a href="#" className="menu-item active">
                  <span className="menu-icon">📦</span>
                  구매내역
                </a>
                <a href="#" className="menu-item">
                  <span className="menu-icon">❤️</span>
                  찜한 상품
                </a>
                <a href="#" className="menu-item">
                  <span className="menu-icon">📱</span>
                  나의 활동
                </a>
                <a href="#" className="menu-item">
                  <span className="menu-icon">⚙️</span>
                  설정
                </a>
              </nav>
            </div>

            {/* 오른쪽 메인 컨텐츠 */}
            <div className="main-content">
              <div className="stats-grid">
                <div className="stat-card">
                  <div className="stat-icon">🛍️</div>
                  <div className="stat-info">
                    <h3>구매한 상품</h3>
                    <p className="stat-number">2</p>
                  </div>
                </div>
                <div className="stat-card">
                  <div className="stat-icon">💝</div>
                  <div className="stat-info">
                    <h3>찜한 상품</h3>
                    <p className="stat-number">5</p>
                  </div>
                </div>
                <div className="stat-card">
                  <div className="stat-icon">💰</div>
                  <div className="stat-info">
                    <h3>포인트</h3>
                    <p className="stat-number">1,200P</p>
                  </div>
                </div>
              </div>

              <div className="recent-purchases">
                <h2 className="section-title">최근 구매내역</h2>
                <div className="purchase-list">
                  {/* 구매 내역이 없을 때 */}
                  <div className="empty-state">
                    <p>아직 구매한 상품이 없습니다.</p>
                    <button className="browse-products-btn">상품 둘러보기</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyPage; 