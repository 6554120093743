import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase.js';
import Navbar from '../../components/Navbar/Navbar.js';
import './Landing.css';
import ImageCarousel from '../../components/ImageCarousel.js';
import heroImage from '../../assets/images/hero.png';
import utckImage from '../../assets/images/utck.png';
import ticketButtonImage from '../../assets/images/ticket-button.png';
import macroImage from '../../assets/images/macro.jpg';
import columnImage from '../../assets/images/column.png';
import LoginModal from '../../components/Auth/LoginModal.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { logger } from '../../utils/logger';


function Landing() {
  const location = useLocation();
  const navigate = useNavigate();
  const [popularProducts, setPopularProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
    rootMargin: '0px 0px 20% 0px'  // 추가된 설정
  });
  const [hideScroll, setHideScroll] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    // URL을 통한 직접 접근인 경우에만 모달을 표시
    if (location.state?.showLoginModal && location.state?.fromDirectAccess) {
      setIsLoginModalOpen(true);
      // state 초기화
      navigate('/', { replace: true });
    }
  }, [location.state, navigate]);

  const handleLoginSuccess = () => {
    setIsLoginModalOpen(false);
    if (location.state?.returnPath) {
      navigate(location.state.returnPath);
    }
  };

  // 인기 상품 가져오기
  useEffect(() => {
    const fetchPopularProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/popular`);
        if (!response.ok) {
          throw new Error('서버 응답 에러');
        }
        const data = await response.json();
        // 데이터가 배열인지 확인
        setPopularProducts(Array.isArray(data) ? data : []);
      } catch (error) {
        logger.error('인기 상품 조회 중 오류:', error);
        setPopularProducts([]); // 에러 시 빈 배열로 설정
      }
    };

    fetchPopularProducts();
  }, []);

  // 이전 상품으로 이동
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? popularProducts.length - 1 : prevIndex - 1
    );
  };

  // 다음 상품으로 이동
  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === popularProducts.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Navbar 스크롤 애니메이션
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    // 스크롤 이벤트 핸들러
    const handleScroll = () => {
      // 진짜 조금만 스크롤 돼도 바로 숨기기 위해 10px만 체크
      if (window.scrollY > 10) {
        setHideScroll(true);
      } else {
        setHideScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // 클린업 함수 ㄱㄱ
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <motion.div
        className="navbar-container"
        animate={{ y: visible ? 0 : -200 }}
        transition={{ duration: 0.3 }}
      >
        <Navbar onLoginClick={() => setIsLoginModalOpen(true)} />
      </motion.div>

      <div className="page-wrapper">
        <div className="landing-container">
          <main className="main-content">
            <div className="hero-section">
              <motion.img
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
                src={heroImage}
                alt="Hero"
                className="hero-image"
              />
              <div className="hero-text">
                <h1>76DEV</h1>
                <h2>더욱 나다운 라이프스타일을 위해.</h2>
              </div>
            </div>
            {/* <div className={`scroll-indicator ${hideScroll || inView ? 'hide' : ''}`}>
              <div className="arrow"></div>
              <div className="arrow"></div> */}
            {/* </div>

            <section className={`features ${inView ? 'visible' : ''}`} ref={ref}>
              <h3>2시간 전부터 진정되는 티켓팅, 스트레스 받으시죠?🎫</h3>
              <h2>손이 느린 사람도, '1열' 앉게 해준 3가지 노하우 - 10만원 상당</h2>
              <div className="features-content">
                <p>안녕하세요. 여러분의 성공적인 티켓팅을 위해 노력하는, 76DEV 입니다.</p>
                <p>저희는 성공적인 티켓팅을 위한 다양한 도구를 가지고 있어요.</p>
                <p>야구, 콘서트, 공연의 오픈런을 도와주는 서비스,</p>
                <p>취켓팅을 도와주는 서비스, 골프장 취소티 예매까지..</p>
                <p><br></br></p>        
                
                <p><br></br></p>        
                <p>여러분을 위해, 성공적인 티켓팅을 위한 팁 3가지를 공개할게요.</p>
                <p>이 글만 보셔도, 상위 20% 순위부터 티켓팅을 할 수 있어요.</p>
                <p>흔하고 일반적인 팁이 아니니, 꼭 하나라도 얻어 가시길 바라요.</p>
                <p style={{ color: '#A0A0A0' }}>(이 글은 윈도우 기준으로 작성되었니다)</p>
                <p><br></br></p>      

                <h2>1. UTCK 3.1</h2>
                <p>한국표준과학연구원(KRISS) 에서 제작 및 배포하는, Windows 전용 시간 동기화 프로그램이에요.</p>
                <img 
                  src={utckImage} 
                  alt="UTCK 3.1" 
                  className="features-image"
                />
                <p><br></br></p>        
                <p>네이버 시계나 네이비즘과 같은 웹 서비스도 있지만, 그런 사이트들 또한 예매처 ( 인터파크, 티켓링크 등 ) 과 같은</p>
                <p>웹 사이트이기 때문에, 시간을 이중으로 거쳐 가져오게 돼요.</p>
                <p>예매 사이트의 시간을 다른 사이트를 거쳐 확인하는 건 부정확할 수밖에 없죠.</p>
                <p><br></br></p>        
                <p>반면에, UTCK 3.1 은 독자님의 컴퓨터와 한국 표준시를 직접 동기해요.</p>
                <p>사진처럼 0.001 초 단위로 동기가 가능해요.</p>
                <p>아래 링크에서 다운 받으실 수 있어요.</p>
                <p><a href="https://www.kriss.re.kr" style={{ color: '#007bff', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">www.kriss.re.kr</a></p>
                <p>진짜는 지금부터 시작합니다.</p>
                <p><br></br></p>      
                <p><br></br></p>
                <p><br></br></p>            
                <h2>2. 예매하기 버튼 미리 활성화 ( 인터파크 전용 )</h2>
                <p>오픈 시간 전에, 예매하기 버튼을 미리 활성화할 수 있어요.</p>
                <p>복잡한 개발 지식을 가진 소수의 업자 ( a.k.a. '플미' )  들만 이용하는 방법이에요.</p>
                <img 
                  src={ticketButtonImage} 
                  alt="설레는 예매 성공" 
                  className="features-image"
                />
                <p><br></br></p>      

                <p>미리 활성화가 중요한 이유 2가지 :</p>
                <p>(1) 새고침이 필요 없다.</p>
                <p>미리 활성화를 하면, 5초 전부터 '새로고침' 이 아닌 '예매하기' 를 연타하면 돼요.</p>
                <p><br></br></p>      
                <p>(2) 빠른 대기열 진입이 가능하다.</p>
                <p>전체 대기열의 상위 1~5% 의 순번으로 진입이 가능해요.</p>
                <p>1만명이 몰리는 공연이라면, 500번대,</p>
                <p>10만명이 몰리는 공연이라면 1100번대까지, 76DEV가 실제로 받은 대기열이에요.</p>
                <p><br></br></p>      
                <p>설명이 매우 구체적이어서, 무료 PDF를 신청하면 메일로 자동 발송돼요.</p>
                <p>스포츠와, 콘서트에서 모두 사용 가능해요.</p>
                <p>아래 링크에서 신청할 수 있어요.</p>
                <p><a href="https://www.kriss.re.kr" style={{ color: '#007bff', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">예매하기 버튼 활성화 방법 무료 PDF 신청하기</a></p>
                <p><br></br></p>      
                <p><br></br></p>
                <p><br></br></p> 
                <h2>3. 76DEV 매크로</h2>
                <p>"0.2초"</p>
                <p>무슨 시간일까요?</p>
                <p>"6자리 영어 보안문자를 입력하는 시간입니다."</p>
                <p>사람 손으로는 불가능한 시간이죠?</p>
                <p>맞습니다. 매크로입니다. 가장 강력한 방법은 매크로예요.</p>
                <p>인터파크 콘서트 기준, 보안문자 입력부터 결제창까지 2.2초에 소요되지 않아요.</p>
                <img 
                  src={macroImage} 
                  alt="매크로 시연" 
                  className="features-image"
                />
                <p><br></br></p>      
                <p>인터파크 안에서라면 콘서트, 야구, 뮤지컬 전 다 사용 가능해요.</p>
                <p>▼실제 인터파크에서 사용하는 모습</p>
                <video 
                    autoPlay 
                    loop 
                    muted 
                    playsInline
                    className="features-image"
                >
                    <source src="https://i.imgur.com/YR0GW70.mp4" type="video/mp4" />
                    매크로 시연 영상
                </video>
                <p><br></br></p> 
                <p>아래에 나오는 후기도 이 매크로를 가지고 예매하신 분들의 후기예요.</p>
                <p>남들은 영어를 치느라 버벅때, 우리는 버튼 하나 '딸깍'으로 예매가 끝나 있습니다.</p>
                <p>찰x푸스, 성시x 공연에서도 당연히 사용이 가능해요.</p>
                <ImageCarousel />
              </div>




            </section>
            <motion.section
              className="popular-products"
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <div className="section-header">
                <h2>인기있는 상품이에요</h2>
                <div className="scroll-buttons">
                  <button 
                    className="scroll-button prev" 
                    aria-label="이전 상품"
                    onClick={handlePrevClick}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24">
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                    </svg>
                  </button>
                  <button 
                    className="scroll-button next" 
                    aria-label="다음 상품"
                    onClick={handleNextClick}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24">
                      <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12z"/>
                    </svg>
                  </button>
                </div>
              </div>
              
              <div className="products-scroll-container">
                <div 
                  className="products-track"
                  style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                    transition: 'transform 0.3s ease-in-out'
                  }}
                >
                  {popularProducts.map((product) => (
                    <div key={product.id} className="product-card">
                      <div className="product-image">
                        <img src={product.imageUrl} alt={product.name} />
                      </div>
                      <div className="product-info">
                        <h3>{product.name}</h3>
                        <div className="price-info">
                          <span className="current-price">{product.currentPrice.toLocaleString()}원</span>
                          <span className="original-price">{product.originalPrice.toLocaleString()}원</span>
                          <span className="discount-rate">
                            {Math.round((1 - product.currentPrice / product.originalPrice) * 100)}%
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </motion.section>

            <section className="featured-columns">
              <div className="section-header">
                <h2>추천 칼럼</h2>
                <div className="scroll-buttons">
                  <button className="scroll-button prev" aria-label="이전 칼럼">
                    <svg width="24" height="24" viewBox="0 0 24 24">
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                    </svg>
                  </button>
                  <button className="scroll-button next" aria-label="다음 칼럼">
                    <svg width="24" height="24" viewBox="0 0 24 24">
                      <path d="M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12z"/>
                    </svg>
                  </button>
                </div>
              </div>
              
              <div className="columns-scroll-container">
                <div className="columns-track">
                  <article className="column-card">
                    <div className="column-image">
                      <img src={columnImage} alt="칼럼 이미지" />
                      <div className="column-category">티켓팅 노하우</div>
                    </div>
                    <div className="column-info">
                      <div>
                        <h3>2024년 콘서트 티켓팅 완벽 가이드</h3>
                        <p className="column-excerpt">
                          올해 달라진 티켓팅 시스템과 새로운 팁을 총정리했습니다. 실전에서 바로 써먹을 수 있는 핵심 노하우만 모았어요.
                        </p>
                      </div>
                      <div className="column-meta">
                        <span className="read-time">8분 읽기</span>
                        <span className="publish-date">2024.03.15</span>
                      </div>
                    </div>
                  </article> */}
                  {/* 추가 칼럼 카드들... */}
                {/* </div>
              </div>
            </section> */}
          </main>
        </div>
      </div>

      <LoginModal 
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)}
        onLoginSuccess={handleLoginSuccess}
      />
    </>
  );
}

export default Landing;