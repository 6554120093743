import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: #101212;
  padding: 2rem 0;
  margin-top: auto;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  color: #DFE0E2;
  font-size: 0.9rem;
  line-height: 1.6;
`;

const CompanyInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1rem;
`;

const InfoItem = styled.p`
  margin: 0;
  color: #A0A0A0;
  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  
  a {
    color: #A0A0A0;
    text-decoration: underline;
    transition: color 0.2s ease;
    
    &:hover {
      color: #DFE0E2;
    }
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #A0A0A0;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <CompanyInfo>
          <div>
            <InfoItem>상호명: 퀀텀소프트</InfoItem>
            <InfoItem>사업자등록번호: 533-06-03120</InfoItem>
            <InfoItem>대표: 박지원</InfoItem>
            <InfoItem>경기도 남양주시 와부읍 수레로116번길 16, 4층</InfoItem>
            {/* <InfoItem>
              메일: <a href="mailto:76macrodev@gmail.com">76macrodev@gmail.com</a>
            </InfoItem> */}
            <InfoItem>010-8914-9725</InfoItem>
          </div>
        </CompanyInfo>
        <Copyright>
          Copyright © 76DEV-Macro. All Rights Reserved
        </Copyright>
      </FooterContent>
    </FooterWrapper>
  );
};

export default Footer; 