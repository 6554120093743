import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
        <p className="loading-text">인증 확인 중...</p>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/" state={{ 
      showLoginModal: true, 
      returnPath: location.pathname,
      fromDirectAccess: true 
    }} />;
  }

  return children;
};

export default PrivateRoute;