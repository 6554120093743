import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import LoginModal from '../Auth/LoginModal';
import './Navbar.css';
import logoImage from '../../assets/images/logo.png';
import userIcon from '../../assets/images/user-icon.png';
import api from '../../utils/api';
import { useAuth } from '../../contexts/AuthContext';
import { logger } from '../../utils/logger';

function Navbar() {
  const { user, setUser } = useAuth();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [initialMode, setInitialMode] = useState('login');
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const confirmLogout = window.confirm('정말 로그아웃 하시겠습니까?');
    if (confirmLogout) {
      try {
        await api.auth.logout();
        await signOut(auth);
        setUser(null);
        navigate('/', { replace: true });
      } catch (error) {
        logger.error('로그아웃 중 오류:', error);
        alert('로그아웃 중 오류가 발생했습니다.');
      }
    }
  };

  const handleProfileClick = () => {
    if (user) {
      navigate('/mypage');
    } else {
      setIsLoginModalOpen(true);
    }
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-top">
          <div className="navbar-left">
            <Link to="/">
              <img src={logoImage} alt="76DEV Logo" className="logo" />
            </Link>
          </div>
          
          <div className="navbar-center">
            <div className="menu-items">
              <Link to="/" className={`nav-link ${isActive('/')}`}>홈</Link>
              <Link to="/products" className={`nav-link ${isActive('/products')}`}>상품</Link>
              <span className="nav-link">무료 칼럼 PDF</span>
              <span className="nav-link">카테고리</span>
            </div>
          </div>

          <div className="navbar-right">
            <div className="auth-buttons">
              {user ? (
                <>
                  <img 
                    src={userIcon}
                    alt="User" 
                    className="user-icon"
                    onClick={handleProfileClick}
                    style={{ cursor: 'pointer' }}
                  />
                  <button 
                    onClick={handleLogout} 
                    className="nav-button login"
                  >
                    로그아웃
                  </button>
                </>
              ) : (
                <>
                  <button 
                    onClick={() => {
                      setInitialMode('login');
                      setIsLoginModalOpen(true);
                    }} 
                    className="nav-button login"
                  >
                    로그인
                  </button>
                  <button 
                    onClick={() => {
                      setInitialMode('signup');
                      setIsLoginModalOpen(true);
                    }} 
                    className="nav-button signup"
                  >
                    회원가입
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="navbar-bottom">
          <Link to="/" className={`nav-link ${isActive('/')}`}>홈</Link>
          <Link to="/products" className={`nav-link ${isActive('/products')}`}>상품</Link>
          <Link to="/pdf" className={`nav-link ${isActive('/pdf')}`}>무료 칼럼 PDF</Link>
          <Link to="/category" className={`nav-link ${isActive('/category')}`}>카테고리</Link>
        </div>
      </nav>

      <LoginModal 
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        initialMode={initialMode}
      />
    </>
  );
}

export default Navbar;