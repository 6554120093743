import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import api from '../utils/api';
import { logger } from '../utils/logger';

const baseURL = process.env.REACT_APP_API_URL || 'https://76devmacro.com';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // 세션 검증
  const verifySession = async () => {
    try {
      const response = await fetch('/api/auth/verify-session', {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
      } else {
        setUser(null);
      }
    } catch (error) {
      logger.error('세션 검증 실패:', error);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  // Firebase 인증 상태 변경 감지
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const idToken = await firebaseUser.getIdToken();
          await api.auth.verifyToken(idToken);
          await verifySession();
        } catch (error) {
          logger.error('토큰 검증 실패:', error);
          setUser(null);
          setLoading(false);
        }
      } else {
        setUser(null);
        setLoading(false);
      }
    });

    // 초기 세션 검증
    verifySession();

    return () => unsubscribe();
  }, []);

  const login = async (idToken) => {
    try {
      await api.auth.verifyToken(idToken);
      await verifySession();
      return true;
    } catch (error) {
      logger.error('로그인 실패:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await api.auth.logout();
      await auth.signOut();
      setUser(null);
    } catch (error) {
      logger.error('로그아웃 실패:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      loading, 
      login, 
      logout, 
      verifySession,
      setUser 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth는 AuthProvider 내부에서만 사용할 수 있습니다.');
  }
  return context;
};