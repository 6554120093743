import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const PaymentComplete = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('처리중...');

  useEffect(() => {
    const processPayment = async () => {
      const paymentKey = searchParams.get('paymentKey');
      const orderId = searchParams.get('orderId');
      const amount = searchParams.get('amount');

      if (!paymentKey || !orderId || !amount) {
        setStatus('결제 정보가 올바르지 않습니다.');
        return;
      }

      try {
        // 서버에 결제 승인 요청
        const response = await fetch('/api/payments/confirm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paymentKey,
            orderId,
            amount: Number(amount),
          }),
        });

        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.message || '결제 승인 중 오류가 발생했습니다.');
        }

        setStatus('결제가 완료되었습니다!');
        // 3초 후 메인 페이지로 이동
        setTimeout(() => {
          navigate('/');
        }, 3000);

      } catch (error) {
        console.error('결제 승인 에러:', error);
        setStatus('결제 승인 중 오류가 발생했습니다.');
      }
    };

    processPayment();
  }, [searchParams, navigate]);

  return (
    <div className="payment-complete-container">
      <div className="payment-status">
        <h2>{status}</h2>
        {status.includes('완료') && (
          <p>잠시 후 메인 페이지로 이동합니다...</p>
        )}
        {status.includes('오류') && (
          <button onClick={() => navigate('/')}>
            메인으로 돌아가기
          </button>
        )}
      </div>
    </div>
  );
};

export default PaymentComplete; 