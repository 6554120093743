// CSRF 토큰 관리
let csrfToken = null;
import { logger } from '../utils/logger';

// HTTPS 강제 적용
const baseURL = process.env.REACT_APP_API_URL || 'https://76devmacro.com';

// CSRF 토큰 발급 함수
async function getCsrfToken() {
  try {
    const response = await fetch(`${baseURL}/api/csrf-token`, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    if (!response.ok) {
      throw new Error('CSRF 토큰 발급 실패');
    }
    
    const data = await response.json();
    csrfToken = data.csrfToken;
    return csrfToken;
  } catch (error) {
    logger.error('CSRF 토큰 발급 실패:', error);
    throw new Error('CSRF 토큰을 가져오는데 실패했습니다.');
  }
}

// CSRF 토큰을 포함한 fetch 함수
export async function fetchWithCSRF(url, options = {}) {
  try {
    if (!csrfToken) {
      await getCsrfToken();
    }

    // URL이 상대 경로인 경우에만 baseURL 추가
    const fullUrl = url.startsWith('http') ? url : `${baseURL}${url}`;

    const defaultOptions = {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      credentials: 'include'
    };

    const response = await fetch(fullUrl, {
      ...defaultOptions,
      ...options,
      headers: {
        ...defaultOptions.headers,
        ...options.headers
      }
    });

    if (!response.ok) {
      if (response.status === 403) {
        csrfToken = null;
        if (!options._retry) {
          return fetchWithCSRF(url, { ...options, _retry: true });
        }
      }
      const error = await response.json();
      throw new Error(error.message || '서버 요청 실패');
    }

    return response;
  } catch (error) {
    logger.error('API 요청 실패:', error);
    throw error;
  }
}

const api = {
  auth: {
    async verifyToken(idToken) {
      const response = await fetchWithCSRF('/api/auth/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ idToken }),
        credentials: 'include'
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || '인증 처리 실패');
      }

      return response;
    },

    async logout() {
      try {
        const response = await fetchWithCSRF('/api/auth/logout', {
          method: 'POST'
        });
        
        // 모든 쿠키 삭제
        document.cookie.split(';').forEach(cookie => {
          const [name] = cookie.split('=');
          document.cookie = `${name.trim()}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        });
        
        csrfToken = null;
        
        return response;
      } catch (error) {
        logger.error('로그아웃 실패:', error);
        throw error;
      }
    }
  }
};

export default api;
